.article {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  background-color: white;
  margin: 15px;
  padding: 100px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}