.container {
  display: flex;
  background-color: #222;
  /* height: 100vh; */
  /* color: white; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* font-size: calc(20px + 2vmin); */
  min-height: 100vh;
}

.container_inner {
  background-color: #fbfbfb;
  width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .container_inner {
    background-color: #fbfbfb;
    width: 100%;
    margin: 0 auto;
  }
}
